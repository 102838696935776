* {
    padding: 0px;
    margin: 0px;
}

.home-contact{
    /* height: 200px; */
    text-align:center;
    display: flex;
    flex-direction: column;
    justify-content:center;
    background: #f4f4f4 none repeat scroll 0 0;

  }



.main {
    background: #f4f4f4 none repeat scroll 0 0;
    height: 165px;
    margin-top: 0;
}

.first {
    /* background: #f4f4f4 none repeat scroll 0 0; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
}

.second {
    /* background: #f4f4f4 none repeat scroll 0 0; */
    font-size: 20;

}

.fifth {

    text-align: center;
    @apply text-2xl
}

h1{
    @apply text-2xl
}

.six {
    /* color:#9d1faa,
    */

    color: #9d1faa;
}

.seven {
    color: black;
    align-items: center;
    

}

.eight {
    margin-top: 4%;
    text-decoration: underline;
    display: flex;
    align-items: center; 
    justify-content: center;
}

.nine {
    font-size: 25px;
    margin-left: 25px;

}

.ten {
    display: flex;
    justify-content: space-between;
    padding: 3% 50px;
}

.addfon {
    font-size: 20px;
}

.detail {
    margin-top: 2%;
    color: black
}

.iconcol {
    background-color: '#e7e7e7';
    color: #189f97;
}

.btn {
    background-color: #189f97;
    color: #000;
    font-size: 20px;
    padding: 10px 60px;
    border-radius: 5px;
    margin: 10px 0px;
    cursor: pointer;

}

.mes {
    background: #f4f4f4 none repeat scroll 0 0;
    border: 80px;
    color: #8c8c8c;
    font-size: 15px;
    letter-spacing: 1px;
    margin-top: 20px;
    padding: 18px 20px;
    width: 100%;
}

.mess {
    background: #f4f4f4 none repeat scroll 0 0;
    border: 80px;
    color: #8c8c8c;
    font-size: 15px;
    letter-spacing: 1px;
    margin-top: 20px;
    padding: 18px 20px;
    width: 100%;
}

.form {
    margin-left: 20px;
    width: 90%;
}

.formspec {
    display: flex;
}

.map {
    width: 100%;
    margin-top: 20px;
}

.inder1 {
    width: 50%;
}

.head-office{
   color: #9d1faa;
}

@media screen and (max-width:500px) {
    .ten {
        display: block;
        padding: 3% 10%;
    }

    .formspec {
        display: block;
    }

    .form {
        margin-left: 00px;
        width: 100%;
    }

    .inder1 {
        width: 100%;
        padding: 0 10px;
    }

    .map {
        width: 100%;
        margin-top: 20px;
        padding: 0 10px;
    }
}


.ten {
    display: flex;
    justify-content: space-between;
    padding: 1% 50px;
}

