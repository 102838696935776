* {
    padding: 0;
    margin: 0;
}

.body {
    color: white;
    width: 100%;
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px;
    gap: 20px;
}

.first-div {
    width: 50%;
}

.second-div {
    width: 50%;
    height: 500px;
    margin-right: 50px;
}

.slider-image {
    width: 100%;
    height: 400px;
    border-radius:6px;
    margin-top: 70px;
}


.ds {
    display: flex;
    justify-content: space-between;
}

.ds1 {
    height: 500px;
    padding: 0 100px;
}

.unlimited {
    color: rgb(0, 0, 0);
}


@media screen and (max-width:500px) {
    .body {
        display: block;
        padding: 20px;
    }

    .first-div {
        width: 100%;
    }

    .second-div {
        width: 100%;
    }

    .second_body {
        display: block;
        padding: 50px;
    }


    .ds {
        display: inline-block;
        justify-content: space-between;
    }

    .ds1 {
        height: 700px;
        padding: 0 50px;
    }

    .first-div {
        width: 100%;
    }

    .second-div {
        width: 100%;
        height: 400px;
    }

    .slider-image {
        height: 280px;
        border-radius: 4px;
        margin-top: 0px;
    }

}

.carousel-control-prev{
    width: 7%;
}

.carousel-control-next{
    width: 7%;
}