* {
    padding: 0%;
    margin: 0%;
}


.Newsletter {
    height: 200px;
    text-align: center;
    background-color: #1d2c38;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.date-num {
    color: #9d1faa;
}

.parent-news {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.child-news {
    border: 2px;
    width: 100%;
    margin-bottom: 1rem;
    padding-right: 4%;
}

@media screen and (max-width:500px) {

    .news-image {
        float: right;
        height: 400px;
        width: 400px;
    }

    .parent-news {
        margin: 1rem;

        padding: 2rem 2rem;

    }

    .child-news {
        border: 2px;
        width: 100%;
        margin-bottom: 1rem;
        padding-right: 4%;
    }

    .date-num {
        color: #9d1faa;
    }
}