* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.nav-item {
    margin-left: 20px;
    margin-top: 5px;
}

.top {
    padding: 10px 50px;
}

.dannav {
    color: white;
}

.nav-item {
    text-decoration: none;
    font-size: 18px;
}

.icon {
    font-size: 20px;
    ;
    margin-right: 10px;
}

.icon1 {
    font-size: 30px;
    margin-left: 30px;
    color: aqua;

}

.select {
    margin-top: -4px;
}

.logo {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 10px;
}

Link {
    font-size: 10px;
}