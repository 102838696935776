.footercomp {
    display: flex;
    justify-content: space-between;
    padding: 3% 10%;
}

.addfon {
    font-size: 20px;
}

.detail {
    margin-top: 2%;
    color: gray;
}

.detail p {
    color: gray;
}

.iconcol {
    background-color: '#e7e7e7';
}


@media screen and (max-width:500px) {
    .footercomp {
        display: block;
        padding: 3% 10%;
    }
}